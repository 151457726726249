import { wh } from './modules/wh';
import { mv } from './modules/mv';
import { hamburger } from './modules/hamburger';
import { header } from './modules/header';
import { smooth } from './modules/smooth';
import { scrollmagic } from './modules/scrollmagic';

window.addEventListener('load', () => {
  scrollmagic();
})

wh();
mv();
hamburger();
header();
smooth();