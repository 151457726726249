export function header() {
  const header = document.querySelector('.js-header');

  const headerView = () => {
    let y = window.scrollY;
    if ( y > 0 ) {
      header.classList.add('is-view');
    } else {
      header.classList.remove('is-view');
    }
  }

  if( location.pathname === '/' ){
    header.style.transition = 'top 0.3s ease';

    window.addEventListener('scroll', () => {
      headerView();
    });
    window.addEventListener('load', () => {
      headerView();
    })
  } else {
    header.classList.add('is-absolute');
  }
}