export function hamburger() {
  const hamburgerTriggers = document.querySelectorAll('.js-hamburgerTrigger');
  const hamburgerOverlay = document.querySelector('.js-hamburgerOverlay');
  const hamburgerMenu = document.querySelector('.js-hamburgerMenu');
  const body = document.querySelector('body');

  const slideUp = (el, duration = 300) => {
    el.style.height = el.offsetHeight + "px";
    el.offsetHeight;
    el.style.transitionProperty = "height, margin, padding";
    el.style.transitionDuration = duration + "ms";
    el.style.transitionTimingFunction = "ease";
    el.style.overflow = "hidden";
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
    setTimeout(() => {
      el.style.display = "none";
      el.style.removeProperty("height");
      el.style.removeProperty("padding-top");
      el.style.removeProperty("padding-bottom");
      el.style.removeProperty("margin-top");
      el.style.removeProperty("margin-bottom");
      el.style.removeProperty("overflow");
      el.style.removeProperty("transition-duration");
      el.style.removeProperty("transition-property");
      el.style.removeProperty("transition-timing-function");
      el.classList.remove("is-open");
    }, duration);
  };

  const slideDown = (el, duration = 300) => {
    el.classList.add("is-open");
    el.style.removeProperty("display");
    let display = window.getComputedStyle(el).display;
    if (display === "none") {
      display = "block";
    }
    el.style.display = display;
    let height = el.offsetHeight;
    el.style.overflow = "hidden";
    el.style.height = 0;
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
    el.style.marginTop = 0;
    el.style.marginBottom = 0;
    el.offsetHeight;
    el.style.transitionProperty = "height, margin, padding";
    el.style.transitionDuration = duration + "ms";
    el.style.transitionTimingFunction = "ease";
    el.style.height = height + "px";
    el.style.removeProperty("padding-top");
    el.style.removeProperty("padding-bottom");
    el.style.removeProperty("margin-top");
    el.style.removeProperty("margin-bottom");
    setTimeout(() => {
      el.style.removeProperty("height");
      el.style.removeProperty("overflow");
      el.style.removeProperty("transition-duration");
      el.style.removeProperty("transition-property");
      el.style.removeProperty("transition-timing-function");
    }, duration);
  };

  const openMenu = () => {
    body.classList.add('is-noscroll');
    hamburgerTriggers.forEach(trigger => {
      trigger.classList.add('is-active');
    });
    hamburgerOverlay.classList.add('is-active');
    slideDown(hamburgerMenu);
  }

  const closeMenu = () => {
    body.classList.remove('is-noscroll');
    hamburgerTriggers.forEach(trigger => {
      trigger.classList.remove('is-active');
    });
    hamburgerOverlay.classList.remove('is-active');
    slideUp(hamburgerMenu);
  }

  hamburgerTriggers.forEach(trigger => {
    trigger.addEventListener('click', function() {
      if(this.classList.contains('is-active')) {
        closeMenu();
      } else {
        openMenu();
      }
    });
  });


  hamburgerOverlay.addEventListener('click', () => {
    closeMenu();
  });
}