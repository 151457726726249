export function mv() {
  const mv = document.querySelector('.js-mv');
  const mvSlider = document.querySelector('.js-mvSlider');
  const mvLogo = document.querySelector('.js-mvLogo');
  const mvLogo_img = document.querySelector('.js-mvLogo_img');
  const mvOverlay = document.querySelector('.js-mvOverlay');
  const mvTtl = document.querySelector('.js-mvTtl');
  const mvSlideBtn = document.querySelectorAll('.js-mvSlideBtn');
  const mvSlideDots = document.querySelector('.js-mvSlideDots');

  const initSwiper = () => {
    const swiper = new Swiper(mvSlider, {
      // Optional parameters
      loop: true,
      effect: 'fade',
      speed: 1000,
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 5000,
      },
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  const mvAnimation = () => {
    mvLogo_img.classList.add('is-fadein');
    // mvLogo のアニメーションが終了した後に実行する処理を設定
    mvLogo.addEventListener('animationend', () => {
      mvLogo.classList.add('is-scaleup');
      mvOverlay.classList.add('is-fadeout');
      initSwiper();

      mvOverlay.addEventListener('animationend', () => {
        mvTtl.classList.add('is-fadein');
        mvSlideBtn.forEach(btn => {
          btn.classList.add('is-fadein');
        });
        mvSlideDots.classList.add('is-fadein');
      }, { once: true });
    }, { once: true }); // once: true でイベントリスナーを一度だけ実行するようにする
  }

  if(mv) {
    mvAnimation();
  }

}